import axios from 'axios';
import React from 'react';
import { useTable, useSortBy } from 'react-table';
import styled from 'styled-components';
import { useEffect } from 'react';
import { useAuth } from "../provider/authProvider";
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertDate } from "../utils/convertDate";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { log, LogLevel } from './../utils/Logger';
import { useTCP } from '../provider/TCPProvider';





const Table = styled.table`
  width: 100%;
  color: #555;
  border-radius: 5px

  background-color: #ffffff;
`;

const Th = styled.th`
  padding: 8px;
  text-align: left;
  color:#111;
  background-color: #ddd;
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  background-color: #f2f2f2;
`;

const Td = styled.td`
  padding: 8px;
  border-bottom: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
`;

export default function DarkTable({ data }) {
  log(LogLevel.INFO, 'data')
  log(LogLevel.INFO, data)
  const columns = React.useMemo(
    () => [
      {
        Header: 'Process id',
        accessor: 'id',
      },
      {
        Header: 'Process TAGS',
        accessor: 'tags',
      },
      {
        Header: 'TCP - Solution',
        accessor: 'app',
      },
      {
        Header: 'Created',
        accessor: 'launched',
        Cell: ({ cell }) => {
          const formattedDate = convertDate(cell.value);
          return <div>{formattedDate}</div>;
        }
      },
      {
        Header: 'Status',
        accessor: 'state',
      }
    ],
    []
  );


  const initialState = {
    sortBy: [
      {
        id: "Created",
        desc: false
      }
    ]
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState
    },
    useSortBy
  );

  const { token } = useAuth();
  const { TCPApp } = useTCP();
  const handleDelete = (event, pid) => {
    event.preventDefault();
    const url = TCPApp + "/process/" + pid;
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.delete(url)
      .then(response => {
        log(LogLevel.INFO, 'DELETE request successful');
        window.location.reload();
        log(LogLevel.INFO, response.data); // Log the response data if needed
      })
      .catch(error => {
        log(LogLevel.ERROR, 'Error deleting resource:', error);
      });
  }

  const handleRowClick = (row) => {
    const url = TCPApp + "/process/" + row.original.id;
    if (row.original.state === 'dead') {
      alert("Vos fichiers vont être téléchargés")
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      axios.get(url)
        .then(response => {
          axios.get(TCPApp + "/process/outputs/" + row.original.id)
            .then(response => {
              if (response.data["outputs"]) {
                axios.get(TCPApp + "/process/outputs/" + row.original.id)
                  .then(response => {
                    if (response.data["outputs"]) {

                      log(LogLevel.INFO, "ResponseData :", response.data)
                      const files = response.data.outputs

                      if (files.length < 10 && !files.map(file => file.key.replace('/', '')).join('').includes('/')) {
                        for (var i = 0; i < files.length; i++) {
                          const downloadUrl = files[i]['url']
                          if (downloadUrl) {
                            const link = document.createElement('a');
                            link.target = "_blank"
                            link.href = downloadUrl;
                            link.click();
                          }
                          else { alert(`This file has been deleted ${files[i]['key']}`) }
                        }
                      } else {
                        const zip = new JSZip();
                        log(LogLevel.INFO, files)
                        const download = (item) => {

                          return fetch(item.url).then((resp) => {
                            zip.file(item.key, resp.blob());
                          });
                        };

                        const downloadAll = () => {
                          const arrOfFiles = files.map((item) => download(item)); //create array of promises
                          Promise.all(arrOfFiles)
                            .then(() => {
                              zip.generateAsync({ type: "blob" }).then(function (blob) {
                                saveAs(blob, row.original.id + ".zip");
                              });
                            })
                            .catch((err) => {
                              log(LogLevel.ERROR, err);
                            });
                        };
                        downloadAll();
                      }

                    } else { alert("Outputs is empty"); log(LogLevel.INFO, response.data) }
                  })
              }
            })
        })
        .catch(error => {
          log(LogLevel.ERROR, 'Erreur de requête', error);
        });

    }
  };



  return (
    <Table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' 🔽'
                      : ' 🔼'
                    : ''}
                </span>
              </Th>

            ))}
            <Th>Outputs</Th>
            <Th>Tickets</Th>
            <Th></Th>
          </tr>


        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                );
              })}
              {<Td {...row.getRowProps()}

              > {row.original.state === 'dead' ? <Button onClick={() => handleRowClick(row)}>Download</Button> : ''}</Td>}
              <Td
                onClick={() => window.open(TCPApp + "/form/process/ticket/" + row.original.id, '_blank')}
                style={{ cursor: 'pointer' }}> Create ticket
              </Td>
              <Td
                onClick={(e) => handleDelete(e, row.original.id)}
                style={{ cursor: 'pointer' }}>  <FontAwesomeIcon icon={faTrash} />
              </Td>
            </tr>

          );
        })}

      </tbody>
    </Table>
  );
};


export function DisplayTableProcesses(props) {
  return (
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>App</th>
          <th>Status</th>
          <th>Created</th>
        </tr>
      </thead>
      <tbody>
        {props.array.map(item => (
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.app}</td>
            <td>{item.status}</td>
            <td>{item.launched}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
