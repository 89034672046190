import React, { useState } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { useAuth } from '../../provider/authProvider';
import { useData } from '../../provider/dataProvider';
import { calculateMD5 } from '../../utils/Md5Sum';
import { useTCP } from '../../provider/TCPProvider';
import { log, LogLevel } from '../../utils/Logger';
const dropzoneStyles = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
};

export default function UploadModale({ single = false, name = "", toggleModal = () => { }, handleAction = () => { }, dropzoneText = "Drag 'n' drop a file here, or click to select a file", setUploadFiles = () => { } }) {

  const [files, setFiles] = useState([]);
  const [filesError, setFilesError] = useState([]);
  const [uploadProgress, setUploadProgress] = useState([]);
  const [uploadUrl, setUploadUrl] = useState(null);
  const { token } = useAuth();
  const { updateData } = useData();
  const { TCPData } = useTCP();

  const addvalue = (newval) => {
    setFilesError([...filesError, newval]);
    log(LogLevel.INFO, [...filesError, newval])
  };

  const updateUploadProgress = (index, newValue) => {
    setUploadProgress(prevArray =>
      prevArray.map((item, i) => (i === index ? newValue : item))
    );
  };

  const uploadFile = async (body, url, f, index, errors) => {
    log(LogLevel.INFO, files)
    try {
      await axios.put(url, f, {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          updateUploadProgress(index, progress);
        },
      });

      log(LogLevel.INFO, body)
      await axios.post(TCPData + '/upload/singlepart/complete', body, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
    } catch (error) {
      errors.push(body.uri)
      log(LogLevel.ERROR, 'Error uploading file:', error);
    }

  };

  const onDrop = async (acceptedFiles) => {
    var errors = []
    setFiles(acceptedFiles);
    var targetFiles = []
    setUploadProgress(new Array(acceptedFiles.length).fill(0));
    var index = 0;
    for (const selectedFile of acceptedFiles) {
      if (selectedFile.size > 4500000000) {
        alert("Upload is not allowed (size too large)")
      }
      else {
        let filename = selectedFile.path || selectedFile.name;
        if (filename.startsWith("/")) {
          filename = filename.substring(1);
        }
        filename = filename.replace(' ', '_');
        /* TODO Uncomment when md5sum will be ready in back
        var md5sum;
        try {
          md5sum = await calculateMD5(selectedFile);
          log(LogLevel.INFO, 'MD5 sum:', md5sum);
        } catch (error) {
          log(LogLevel.ERROR, 'Error calculating MD5:', error);
        }
*/
        const body = {
          uri: name + filename,
          //size: selectedFile.size.toString(),
          //md5sum: md5sum,
        }
        try {
          // Step 1: Get upload URL from the server
          log(LogLevel.INFO, body)
          const { data } = await axios.post(TCPData + '/upload/singlepart', body, {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          });
          setUploadUrl(data.url);
          log(LogLevel.INFO, data.url)
          const fullbody = {
            uri: name + filename,
            size: selectedFile.size,
            //md5sum: md5sum,
          }
          targetFiles.push(name + filename)
          await uploadFile(fullbody, data.url, selectedFile, index, errors)
          await updateData();
        } catch (error) {
          log(LogLevel.ERROR, 'Error getting upload URL:', error);
        }
        index = index + 1
      }
    };
    setUploadFiles(targetFiles)
    setFilesError(errors)
    //handleAction();
    setFiles([]);

  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    ...(single && { maxFiles: 1 }) // Utilise maxFiles: 1 seulement si single est vrai
  });

  return (
    <div style={{ width: "100%" }}>
      {files.length === 0 ? (
        <div {...getRootProps()} style={dropzoneStyles}>
          <input {...getInputProps({ multiple: !single })} /> {/* Ajoutez multiple: false seulement si single est false */}
          {isDragActive ? (
            <p>Drop the file here ...</p>
          ) : (
            <p>{dropzoneText}</p>
          )}
        </div>
      ) : (
        files.map((item, index) => (
          <div key={index}>
            <p>{item.name} <progress style={{ marginLeft: "10px" }} value={uploadProgress[index]} max="100" /></p>
          </div>
        ))
      )}
      {filesError.length > 0 && <p>
        <p> Something went wrong during the upload :</p>
        {filesError.map((item, index) => (
          <div key={index}>
            <p>{item}</p>
          </div>
        ))}
      </p>}
    </div>)

}