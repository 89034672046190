import React from 'react';
import { useState } from 'react';
import './AutoGeneratedForm.css';
import { useEffect } from 'react';
import { useAuth } from '../../provider/authProvider';
import { useNavigate } from 'react-router-dom';
import ModalFileSelector from '../actions/ModalFileSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import ModalBody from './ModalBody';
import { useTCP } from './../../provider/TCPProvider';
import { log, LogLevel } from '../../utils/Logger';
import Waiting from '../Waiting';

const AutoGeneratedForm = ({ jsonSchema, domain, app }) => {

  const { token, user } = useAuth();
  const { TCPApp } = useTCP();
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState({});
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

  const [modal, setModal] = useState(false)
  const [modalBody, setModalBody] = useState(false)
  const [body, setBody] = useState({})

  const [currentSet, setCurrentSet] = useState()

  const [selectedGroup, setSelectedGroup] = useState('Personal Files');
  const [isOpen, setIsOpen] = useState({});

  const [single, setSingle] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [MessageLaunch, setMessageLaunch] = useState(false)

  const makeDescription = (text) => {
    var descr = text.replace(/\[(.*?)\]/g, '');
    descr = descr.charAt(0).toUpperCase() + descr.slice(1)
    return descr
  }
  const toggleModal = () => {
    setModal(!modal);
  };

  const toggleModalMessLaunch = () => {
    setMessageLaunch(!MessageLaunch);
  };

  const toggleModalBody = () => {
    setModalBody(!modalBody);
  };

  if (modal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }
  if (modalBody) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }

  if (MessageLaunch) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }

  useEffect(() => {
    initializeFormValues(jsonSchema.content[0].content.top);
  }, [jsonSchema]);

  const initializeFormValues = (schema, parent = '') => {
    const initialFormValues = {};
    log(LogLevel.INFO, "schema", schema)
    log(LogLevel.INFO, "parent", parent)

    Object.keys(schema.properties).forEach((fieldName) => {
      const field = schema.properties[fieldName];
      const fullFieldName = parent ? `${parent}.${fieldName}` : fieldName;
      log(LogLevel.INFO, "Fullfield :", fullFieldName)
      if ((field.type === 'array') && (field.items['format'] === 'uri')) {
        //initialFormValues[fieldName] = ['']; // Initialiser avec au moins un champ
        //handleAddArrayItem(fullFieldName)
        initialFormValues[fieldName] = '';
      } else if (field.type === 'array') {
        handleAddArrayItem(fullFieldName)
      } else if (field.type === 'object') {
        initialFormValues[fieldName] = initializeFormValues(field, fieldName);
      } else {
        initialFormValues[fieldName] = ''; // Initialiser avec une chaîne vide

      }
    });
    return initialFormValues;
  };

  const handleInputChange = (fieldName, value) => {
    setFormValues((prevValues) => {
      log(LogLevel.INFO, fieldName)
      log(LogLevel.INFO, value)
      return {
        ...prevValues,
        [fieldName]: value,
      };
    })
  };

  const handleInputArrayChange = (fieldName, index, value) => {
    setFormValues((prevValues) => {
      if (Array.isArray(prevValues[fieldName])) {
        const newArray = [...prevValues[fieldName]];
        newArray[index] = value;
        return {
          ...prevValues,
          [fieldName]: newArray,
        };
      } else if (typeof prevValues[fieldName] === 'object') {
        return {
          ...prevValues,
          [fieldName]: {
            ...prevValues[fieldName],
            [index]: value,
          },
        };
      } else {
        return {
          ...prevValues,
          [fieldName]: value,
        };
      }
    });
  };

  const handleAddArrayItem = (fieldName) => {
    setFormValues((prevValues) => {
      const newArray = prevValues[fieldName] ? [...prevValues[fieldName], ''] : [''];
      return {
        ...prevValues,
        [fieldName]: newArray,
      };
    });
  };

  const handleDeleteArrayItem = (fieldName, index) => {
    setFormValues((prevValues) => {
      const newArray = prevValues[fieldName].filter((_, i) => i !== index);
      return {
        ...prevValues,
        [fieldName]: newArray,
      };
    });
  };

  const renderFormFields = (schema, parentFieldName = '') => {
    const requiredFields = schema.required || [];

    return Object.keys(schema.properties).map((fieldName) => {
      const field = schema.properties[fieldName];
      const isRequired = requiredFields.includes(fieldName);
      const fullFieldName = parentFieldName ? `${parentFieldName}.${fieldName}` : fieldName;

      if (!showAdvancedOptions && !isRequired) {
        return null; // Ne rend pas les champs optionnels s'ils ne sont pas affichés
      }

      if (field.type === 'object') {
        return (
          <div key={fullFieldName}>
            <h3>{fullFieldName.toUpperCase()}</h3>
            {renderFormFields(field, fullFieldName)}
          </div>
        );
      } else if ((field.type === 'array') && field.items['format'] === "uri") {
        return (
          <div key={fullFieldName}>
            <b>{fieldName.toUpperCase()} : </b>  {makeDescription(field.description)}
            {
              <div >
                <input
                  className="pathInput"
                  type="text"
                  name={`${fullFieldName}`}
                  onChange={(e) => handleInputChange(fullFieldName, e.target.value)}
                  value={formValues[fullFieldName]}
                  onClick={() => {
                    setCurrentSet(fullFieldName)
                    log(LogLevel.INFO, fullFieldName)
                    setSingle(false)
                    setModal(true)
                  }}
                />
              </div>

            }
          </div >
        );
      } else if (field['format'] === "uri") {
        return (
          <div key={fullFieldName}>
            <b>{fieldName.toUpperCase()} : </b>  {makeDescription(field.description)}
            {
              <div >
                <input
                  className="pathInput"
                  type="text"
                  name={`${fullFieldName}`}
                  onChange={(e) => handleInputChange(fullFieldName, e.target.value)}
                  value={formValues[fullFieldName]}
                  onClick={() => {
                    setCurrentSet(fullFieldName)
                    log(LogLevel.INFO, fullFieldName)
                    setSingle(true)
                    setModal(true)
                  }}
                />
              </div>
            }
          </div>
        );
      } else if ((field.type === 'array')) {
        const inputType = mapJsonTypeToInputType(field.type);
        return (
          <div key={fullFieldName}>
            <b>{fieldName.toUpperCase()} : </b>
            <span className="add-button" onClick={() => handleAddArrayItem(fullFieldName)}>
              &#xFE62;
            </span>
            {formValues[fullFieldName] &&
              formValues[fullFieldName].map((item, index) => (
                <div key={index}>
                  <input
                    className="pathInput"
                    type="text"
                    name={`${fullFieldName}[${index}]`}
                    value={item}
                    onChange={(e) => handleInputArrayChange(fullFieldName, index, e.target.value)}
                  />
                  {index > 0 && (
                    <span className="delete-button" onClick={() => handleDeleteArrayItem(fullFieldName, index)}>
                      &#10060;
                    </span>
                  )}
                </div>
              ))}
          </div>
        );

      } else if ((field.type === 'string') && (field?.enum)) {
        return (
          <div key={fullFieldName}>
            <b>{fieldName.toUpperCase()} : </b>  {makeDescription(field.description)}
            {
              <div >
                <select id="dropdown" className="pathInput"
                  type="text" value={formValues[fullFieldName]} onChange={(e) => handleInputChange(fullFieldName, e.target.value)}>
                  <option value=""></option>
                  {field.enum.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            }
          </div>
        );
      }
      else {
        const inputType = mapJsonTypeToInputType(field.type);
        return (
          <div key={fullFieldName}>
            <b>{fieldName.toUpperCase()} : </b> {field.description}

            <input
              className="pathInput"
              type={inputType}
              name={fullFieldName}
              value={formValues[fullFieldName] || ''}
              onChange={(e) => handleInputChange(fullFieldName, e.target.value)}
              required={isRequired}
            />
          </div>
        );
      }
    });
  };

  const mapJsonTypeToInputType = (jsonType) => {
    // Ajoutez ici la logique pour mapper les types JSON aux types d'input HTML
    switch (jsonType) {
      case 'string':
        return 'text';
      case 'number':
        return 'number';
      case 'boolean':
        return 'checkbox';
      // Ajoutez d'autres cas selon vos besoins
      default:
        return 'text';
    }
  };


  const createBodyFromValues = (inputObject) => {
    log(LogLevel.INFO, "InputObject")
    log(LogLevel.INFO, inputObject)
    const outputObject = { inputs: {} };
    // Loop through each key in the input object
    for (const key in inputObject) {
      if (inputObject.hasOwnProperty(key)) {
        // Check if the key starts with "inputs."
        if (key.startsWith("inputs.")) {
          // Extract the field name after "inputs."
          const fieldName = key.replace("inputs.", "");
          // Check if the field name is not an empty string
          if (inputObject[key] !== "") {
            // Add the field to the output object under "inputs" key
            outputObject.inputs[fieldName] = inputObject[key];
          }
        } else {
          if (!(inputObject[key] == "" || inputObject[key] == [""])) {
            outputObject[key] = inputObject[key];
          }
        }
      }
    }
    return outputObject;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true)
    log(LogLevel.INFO, 'Form Values:', formValues);
    const body = createBodyFromValues(formValues);
    log(LogLevel.INFO, body)

    // Ajoutez ici la logique pour traiter les valeurs du formulaire
    const url = `${TCPApp}/${domain}/${app}/run`;
    log(LogLevel.INFO, url)
    log(LogLevel.INFO, JSON.stringify(body))
    const data = body
    log(LogLevel.INFO, JSON.stringify(data))

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Authorization': `Bearer ${token}`,
        'accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (response.status == 200) {
          setMessageLaunch("Congratulations! Your process has been launched ! You will receive an email once the process is completed. You can follow your processes in the Processes tab.")
          setSubmitting(false)
        }
        else {
          setMessageLaunch("An error occurred")
          setSubmitting(false)
        }
        log(LogLevel.INFO, response.status)
      })

  };

  const handleBody = (e) => {
    e.preventDefault();
    log(LogLevel.INFO, 'Form Values:', formValues);
    const currentBody = createBodyFromValues(formValues);
    setBody(JSON.stringify(currentBody))
    setModalBody(true)
  };

  return (
    <>
      <Link to={"/products/" + domain}>
        <span style={{ color: "#fff", fontSize: '3em' }}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </span>
      </Link>
      {submitting && <Waiting />}
      {MessageLaunch && (
        <h1 style={{ color: "#FFF" }}> {MessageLaunch} </h1>
      )}
      {!submitting && !MessageLaunch &&
        <>
          {modal && (
            <ModalFileSelector single={single} expanded={isOpen} setExpanded={setIsOpen} selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} toggleModal={toggleModal} setSelection={(list) => { log(LogLevel.INFO, currentSet); log(LogLevel.INFO, list); handleInputChange(currentSet, list) }} />
          )}
          {modalBody && (
            <ModalBody toggleModal={toggleModalBody} body={body} />
          )}
          <form className="dynamic-form" >
            <div className="form-fields">
              {renderFormFields(jsonSchema.content[0].content.top)}
            </div>
            {!user?.scope.includes("demo") && <>
              < span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}>        {showAdvancedOptions ? 'Hide Advanced Settings' : 'Advanced Settings'}
              </span>

              <div style={{ textAlign: 'right' }}>
                {showAdvancedOptions ? <span style={{ cursor: 'pointer' }} onClick={handleBody} > <FontAwesomeIcon icon={faArrowRight} /> Create body for api utilisation</span> : ''}
              </div>
            </>}
            <button type="submit" onClick={handleSubmit} className="submit-button">Submit</button>
          </form ></>}
    </>
  );
};

export default AutoGeneratedForm;